import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import SummaryCard from '../charts/SummaryCard.js';
import TasksPieChart from '../charts/TasksPieChart.js';
import TasksBarChart from '../charts/TasksBarChart.js';
import SideBar from './SideBar.js';
import axios from 'axios';

const Analytics = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const [tasksData, setTasksData] = useState(null); // State to hold the tasks data

  // Fetch API data on component mount
  useEffect(() => {
    const setCompanyData = async () => {
      try {
        const companyName = window.sessionStorage.getItem('companyName');
        const response = await axios.post('https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompletedtasks', {
          company: companyName
        });
        
        const data = response.data;

        // Update tasksData state
        setTasksData(data);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching the tasks data", error);
        setLoading(false); // Even on error, stop showing the loader
      }
    };

    // Call the function to fetch data on component mount
    setCompanyData();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  // If the data is still loading, show a loader
  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <CircularProgress />
        <p>Loading analytics data...</p>
      </div>
    );
  }

  // If tasksData is still not available, return null (optional safeguard)
  if (!tasksData) {
    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <SideBar />
      <div style={{ flexGrow: 1, padding: 20 }}>
        <h1>Analytics</h1>
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <SummaryCard title="Active Bots" value="22" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SummaryCard title="Paths" value="48" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SummaryCard title="Forklifts" value="33" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SummaryCard title="Stations" value="22" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SummaryCard title="People helped" value="48" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SummaryCard title="Zones" value="33" />
          </Grid>
        </Grid>

        <div style={{ marginTop: 30 }}>
          <Grid container spacing={3}>
            {/* Pass the fetched tasksData to charts */}
            <Grid item xs={12} md={6}>
              <TasksPieChart data={tasksData.stationdata} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TasksBarChart data={tasksData.companydata} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
