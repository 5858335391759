import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const SummaryCard = ({ title, value }) => {
  return (
    <Card style={{ backgroundColor: '#f4f6f8', padding: 10 }}>
      <CardContent>
        <Typography variant="h5">{value}</Typography>
        <Typography variant="subtitle1">{title}</Typography>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
