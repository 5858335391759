import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [contact, setContact] = useState('');
  const [password, setPassword] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://drobot-admin-v2-a2def93839bb.herokuapp.com/register', {
        username,
        email,
        fullName,
        contact,
        password,
        contactNumber        
      });
      if (response.data.message === 'User registered successfully.') {
        navigate('/login');
      } else {
        console.error('Registration failed:', response.data);
      }
    } catch (error) {
      console.error('Registration error:', error);
    }
  };

  return (
    <div className="w-full h-screen bg-blue-200 flex items-center justify-center">
      <div className=" max-w-lg bg-white px-8 py-6 rounded-lg shadow-md">
        <h1 className="text-3xl text-center font-semibold text-zinc-700 mb-4">
          Register your company
        </h1>
        <p className="text-center text-md mb-4">Sign up to see more</p>
        <form onSubmit={handleRegister} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
            <input
              id="username"
              name="username"
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="fullname" className="block text-sm font-medium text-gray-700">Full Name</label>
            <input
              id="fullname"
              name="fullname"
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="contact" className="block text-sm font-medium text-gray-700">Contact</label>
            <input
              id="contact"
              name="contact"
              type="tel"
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
              placeholder="Contact Number"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="birthdate" className="block text-sm font-medium text-gray-700">contactNumber</label>
            <input
              id="birthdate"
              name="birthdate"
              type="date"
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white rounded-full py-2 px-4 font-semibold"
          >
            Continue
          </button>
          <p className="text-xs text-center text-gray-700 mt-2">
            By continuing, you agree to Drobot's Terms of Service and acknowledge you've read our Privacy Policy. Notice at collection.
          </p>
        </form>
        <a href="/" className="block text-center text-sm font-semibold mt-4 text-red-600">
          Already a member? Log in
        </a>
      </div>
    </div>
  );
};

export default Register;
