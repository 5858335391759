import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const TasksBarChart = ({ data }) => {
  // Example data for the bar chart
  const barData = [
    { name: 'Station North', tasks: 84 },
    { name: 'Station South', tasks: 46 },
    { name: 'Station West', tasks: 24 }
  ];

  return (
    <BarChart
      width={400}
      height={300}
      data={barData}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="tasks" fill="#82ca9d" />
    </BarChart>
  );
};

export default TasksBarChart;
