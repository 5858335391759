import React, { createContext, useContext, useState } from "react";

const Theme = createContext();
const PathDrawContext = createContext();
const PathAIDrawContext = createContext();
const PathContext = createContext();

function ThemeProvider({ children }) {
  const [theme, setTheme] = useState("light");
  const [ToRecord, setToRecord] = useState(false);
  const [selectedPath, setSelectedPath] = useState([]);
  const [selectedStation, setSelectedStation] = useState([]);
  const [pathRecord, setPathRecord] = useState({ x: [], y: [] });
  const [showStation, setShowStation] = useState(true);
  const [showBots, setShowBots] = useState(true);
  const [showpath, setShowpath] = useState(true);
  const [canvasWidth, setCanvasWidth] = useState(1);
  const [AIpath, setAIpath] = useState(false);
  const[tagName,settagName]=useState();
  const [AIpathArray, setAIpathArray] = useState({ x: [], y: [] });
  const [stationRecord, setStationRecord] = useState(false);
  const [AIPathSensitivity, setAIPathSensitivity] = useState(1);
  const [tracePath, setTracePath] = useState(false);
  const [stationMarkedCoordinate, setStationMarkedCoordinate] = useState({
    x: null,
    y: null,
  });
  const [botTraceArray,setBotTraceArray]=useState({
    x:[],
    y:[]
  });
  const [jitterbotCoordinates, setjitterbotCoordinates] = useState({
    x:[],
    y:[]
  });
  const [cameraOffset, setCameraOffset] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });

  return (
    <Theme.Provider value={{ theme, setTheme }}>
      <PathDrawContext.Provider value={{ ToRecord, setToRecord }}>
        <PathAIDrawContext.Provider
          value={{ AIpath, setAIpath, AIpathArray, setAIpathArray }}
        >
          <PathContext.Provider
            value={{
              pathRecord,
              setPathRecord,
              showStation,
              setShowStation,
              showpath,
              setShowpath,
              canvasWidth,
              setCanvasWidth,
              cameraOffset,
              setCameraOffset,
              selectedPath,
              setSelectedPath,
              showBots,
              setShowBots,
              selectedStation,
              setSelectedStation,
              stationRecord,
              setStationRecord,
              stationMarkedCoordinate,
              setStationMarkedCoordinate,
              AIPathSensitivity,
              setAIPathSensitivity,
              tracePath,
              setTracePath,
              tagName,
              settagName,
              botTraceArray,
              setBotTraceArray,
              jitterbotCoordinates, 
              setjitterbotCoordinates
            }}
          >
            {children}
          </PathContext.Provider>
        </PathAIDrawContext.Provider>
      </PathDrawContext.Provider>
    </Theme.Provider>
  );
}

export const useTheme = () => {
  return useContext(Theme);
};

export const usePathDraw = () => {
  return useContext(PathDrawContext);
};
export const useAIPathDraw = () => {
  return useContext(PathAIDrawContext);
};
export const useRecordPath = () => {
  return useContext(PathContext);
};

export default ThemeProvider;
