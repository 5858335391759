import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const TasksPieChart = ({ data }) => {
  // Example data transformation for pie chart
  const pieData = [
    { name: 'Scheduled', value: 120 },
    { name: 'User requested', value: 77 },
    { name: 'Filler', value: 24 }
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  return (
    <PieChart width={400} height={300}>
      <Pie
        data={pieData}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={100}
        fill="#8884d8"
        label
      >
        {pieData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
};

export default TasksPieChart;
