import React, { useEffect, useState } from "react";
import { useTheme } from "../Context/Context.js";
import Logo from "../../utils/Logo.png";
import { usePathDraw } from "../Context/Context.js";
import { useAIPathDraw } from "../Context/Context.js";
import { useRecordPath } from "../Context/Context.js";
import axios from "axios";
import convertToReal from "../helper_functions/convertToRealCoordinates.js";
import { useNavigate } from "react-router-dom";
function SideBar({ cameraZoom }) {
  const { theme, setTheme } = useTheme();
  const {
    pathRecord,
    setPathRecord,
    showStation,
    setShowStation,
    showpath,
    setShowpath,
    canvasWidth,
    setCanvasWidth,
    cameraOffset,
    setCameraOffset,
    selectedPath,
    setSelectedPath,
    selectedStation,
    setSelectedStation,
    showBots,
    setShowBots,
    stationRecord,
    setStationRecord,
    stationMarkedCoordinate,
    setStationMarkedCoordinate,
    tracePath,
    setTracePath,
    tagName,
    settagName,
    botTraceArray,
    setBotTraceArray,
    jitterbotCoordinates, 
    setjitterbotCoordinates
  } = useRecordPath();
  const { ToRecord, setToRecord } = usePathDraw();
  const { AIpath, setAIpath, AIpathArray, setAIpathArray } = useAIPathDraw();
  const [toggle, setToggle] = useState(false);
  const [showList, setShowList] = useState(false);
  const [recordList, setRecordList] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [Bots, setBots] = useState(false);
  const [showIndividualPath, setshowIndividualPath] = useState(false);
  const [showIndividualStation, setshowIndividualStation] = useState(false);
  const [People, setPeople] = useState(false);
  const [Paths, setPaths] = useState(false);
  const [Materials, setMaterials] = useState(false);
  const [Forklifts, setForklifts] = useState(false);
  const [Stations, setStations] = useState(false);
  const [Zones, setZones] = useState(false);
  const [showStationRecord, setShowStationRecord] = useState(false);
  const img = new Image();
  img.src = "Drobot.jpg";
  const pathData = window.sessionStorage.getItem("pathData");
  const paths = JSON.parse(pathData).paths;
  const stationData = window.sessionStorage.getItem("stationData");
  const userEmail=JSON.parse(window.sessionStorage.getItem("data")).email;
  const companyName=(window.sessionStorage.getItem("companyName"));
  const CompanyLogo=JSON.parse(window.sessionStorage.getItem("companyData")).photo_url;
  const companymap= window.sessionStorage.getItem("companyName");
  const navigate = useNavigate();
  // console.log(companyName);
  //All the functions
  const handleSelectAll = () => {
    const newValue = !selectAll;
    setSelectAll(newValue);
    setBots(!Bots);
    setPaths(!Paths);
    setShowpath(!showpath);
    setShowStation(!showStation);
    setPeople(!People);
    setForklifts(!Forklifts);
    setMaterials(!Materials);
    setZones(!Zones);
    setStations(!Stations);
    setShowBots(!showBots);
  };
  useEffect(()=>{
// console.log(botTraceArray);
},[botTraceArray])

  const BotsHandler = () => {
    setShowBots(!showBots);
  };
  const PathsHandler = () => {
    setPaths(!Paths);
    setShowpath(!showpath);
  };
  const PeopleHandler = () => {
    setPeople(!People);
  };
  const ForkliftsHandler = () => {
    setForklifts(!Forklifts);
  };
  const MaterialsHandler = () => {
    setMaterials(!Materials);
  };
  const ZonesHandler = () => {
    setZones(!Zones);
  };
  const StationsHandler = () => {
    setShowStation(!showStation);
  };
  const RemoteControlHandler = () => {
    //write the function to handle Remote Control event here
  };
  const UploadTaskHandler = () => {
    //write the function to handle Upload Task event here
  };
  const AnalyticsHandler = () => {
    //write the function to handle Analytics  event here(may be a redirect)
    navigate('/analytics'); 
  };
  const AIpathHandler = () => {
    setAIpath(!AIpath);
  };
  const clearPathHandler=async()=>{
    console.log("a");
    
    setPathRecord({
      x: [],
      y: [],
    });
  }

  const handleDeletePath = async (pathame) => {
    if (window.confirm("Are you sure you want to delete this path?")) {
      try {
        await axios.post('https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletepath', { pathame, companymap });
        alert("Path deleted successfully!");
        setSelectedPath(prevSelectedPath => 
          prevSelectedPath.filter(path => path.pathame !== pathame)
        );
        const data = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getpathdata", { company: companymap });
        window.sessionStorage.setItem(
          "pathData",
          JSON.stringify(data.data)
        );
      } catch (error) {
        console.error("Error deleting path:", error);
        alert("Failed to delete path.");
      }
    }
  };


  const handleDeleteStation = async (stationName) => {
    if (window.confirm("Are you sure you want to delete this station?")) {
      try {
        await axios.post('https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletestation', { stationName, companymap });
        alert("Station deleted successfully!");
        setSelectedStation(prevSelectedStations => 
          prevSelectedStations.filter(station => station.stationName !== stationName)
        );
        const data=await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",{company:companyName});
        await window.sessionStorage.setItem("stationData", JSON.stringify(data.data["stationdata"])); 
      } catch (error) {
        console.error("Error deleting station:", error);
        alert("Failed to delete station.");
      }
    }
  };
  const pathRecordHandler = async () => {
    
    console.log(AIpathArray);

    var xFlipped = 1;
    var yFlipped = -1;
    var scaleFactor = 24.1304520095363,
      originX = 23462.84145899498 / scaleFactor,
      originY = yFlipped * (-55755.01018319527 / scaleFactor);
    if (AIpathArray.x.length !== 0) {
      const speed = window.prompt("Enter the speed:");
      const distance = window.prompt("Enter the distance:");
      const companyName = sessionStorage.getItem("companyName");
      console.log(AIpathArray);
      var convertedX = 0;
      var convertedXPath = [];
      var convertedY = 0;
      var convertedYPath = [];

      for (var i = 0; i < AIpathArray.x.length; i++) {
        convertedX =
          ((AIpathArray.x[i] / xFlipped +
            (xFlipped * img.naturalWidth) / 2 -
            xFlipped * originX) *
            scaleFactor) /
          10;
        convertedY =
          ((AIpathArray.y[i] / yFlipped +
            (yFlipped * img.naturalHeight) / 2 -
            yFlipped * originY) *
            scaleFactor) /
          10;

        console.log("converted", convertedX);
        convertedXPath.push(convertedX);
        convertedYPath.push(convertedY);
      }

      let finalPath = {
        x: [...convertedXPath],
        y: [...convertedYPath],
      };

      // Log the converted paths
      // console.log("Converted XPath:", convertedXPath);
      // console.log("Converted YPath:", convertedYPath);
      console.log(pathRecord);
      if (speed && distance) {
        try {
          const response = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/savepath", {
            path: finalPath,
            speed: speed,
            distance: distance,
            companyname: companyName,
          });
          console.log(response);

          setPathRecord({
            x: [],
            y: [],
          });

          // Optionally, you can add a success message or confirmation
          if (response.status === 200) {
            const data = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getpathdata", {
              company: companyName,
            });
            window.sessionStorage.setItem(
              "pathData",
              JSON.stringify(data.data)
            );
            alert("Path saved successfully!");
          }
        } catch (err) {
          console.error("Error saving path:", err);
        }
      } else {
        alert("Speed and distance are required!");
      }
    }
    setToRecord(false);
  };
  const TracePathSaveHandler=async()=>{
    console.log(botTraceArray);
    
    if(botTraceArray.x.length==0){
      // console.log("botTraceArray",botTraceArray);
      
      alert("No path has been Traced");
      setTracePath(false);
      return;
    }
    const speed = window.prompt("Enter the speed:");
      const distance = window.prompt("Enter the distance:");
      if (speed && distance) {
        try {
          const response = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/savepath", {
            path: botTraceArray,
            speed: speed,
            distance: distance,
            companyname: companyName,
          });
          console.log(response);

          setBotTraceArray({
            x: [],
            y: [],
          });
      setTracePath(false);
          if (response.status === 200) {
            const data = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getpathdata", {
              company: companyName,
            });
            window.sessionStorage.setItem(
              "pathData",
              JSON.stringify(data.data)
            );
            alert("Path saved successfully!");
          }
        } catch (err) {
          console.error("Error saving path:", err);
      setTracePath(false);
      setBotTraceArray({
        x: [],
        y: [],
      });

        }
      }
      else{
        alert("Speed and Distance are required");
      setTracePath(false);

      }
  }
  const clearTracedPathHandler=()=>{
    setBotTraceArray({
      x: [],
      y: [],
    });
    setjitterbotCoordinates({
      x: [],
      y: [],
    });
    setTracePath(!tracePath);
  }
  const TracePathHandler=()=>{
    const tagName = window.prompt('Enter the tag name to trace:');
    if(tagName){
    settagName(tagName);
    setTracePath(!tracePath);
    }
    else {
      alert(`Tag with ID "${tagName}" not found!`);
    }
  }
  
  const recordStationHandler = () => {
    setShowStationRecord(!showStationRecord);
    if (stationRecord == true) {
      setStationRecord(false);
      setStationMarkedCoordinate({
        x: null,
        y: null,
      });
    } else {
      setStationRecord(true);
    }
  };
  const stationSave = async () => {
    const stationConvertedCoordinates = convertToReal(
      stationMarkedCoordinate.x,
      stationMarkedCoordinate.y
    );
    console.log(stationConvertedCoordinates);
    var nameStation = window.prompt("Enter the name of Station:");
    const companyName = window.sessionStorage.getItem("companyName");
    try {
      const res = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/savestation", {
        stationCoordinate: {
          x: stationConvertedCoordinates.convertedX,
          y: stationConvertedCoordinates.convertedY,
        },
        companyname: companyName,
        stationName: nameStation,
      });
      if (res.status === 200) {
        const data=await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",{company:companyName});
        await window.sessionStorage.setItem("stationData", JSON.stringify(data.data["stationdata"])); 
        alert("Station saved successfully!");
        setStationMarkedCoordinate([], []);
      }
    } catch (error) {
      console.log(error);
      setStationMarkedCoordinate([], []);
      alert("error occured while saving station");
    }
    setShowStationRecord(!showStationRecord);
    console.log(
      convertToReal(stationMarkedCoordinate.x, stationMarkedCoordinate.y)
    );
  };

  const clearStationHandler=async()=>{  
    setStationMarkedCoordinate({
      x: null,
      y: null,
    });
    setStationRecord(true);
  }

  const handleThemeToggle = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  const PathDrawHandler = () => {
    if (ToRecord == true) {
      setToRecord(false);
      setPathRecord({
        x: [],
        y: [],
      });
    } else {
      setToRecord(true);
    }
  };

  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem('auth');
    alert('Logged out successfully');
    navigate('/login');
  };
  return (
    <>
      <div
        className={` h-screen font-sans transition-all ${
          toggle ? " w-max" : "w-60"
        }`}
        style={{ backgroundColor: theme === "light" ? "#015D81" : "#0C2F3A" }}
        // backgroundColor="015D81"
      >
        <div
          className={`relative left-0 h-screen overflow-hidden transition-all ${
            toggle ? "w-max" : "w-60"
          } `}
          style={{ backgroundColor: theme === "light" ? "#015D81" : "#0C2F3A" }}
          // backgroundColor="015D81"
        >
          <div
            className={` flex flex-wrap items-center justify-between ${
              toggle ? "py-2" : "p-2"
            } gap-2 `}
          >
            <div
              className={`${
                toggle ? "w-[100%]" : " w-max"
              } flex gap-2 justify-evenly items-center`}
            >
              <img
                src={toggle ? Logo : "svgs/drobot_logo.svg"}
                alt="Drobot Logo"
                className={` self-center h-[2.5rem]  `}
              />
            </div>

            <div
              className={`${
                toggle ? "w-[100%]" : "w-auto"
              } flex gap-2 justify-evenly items-center`}
            >
              <button>
                <img
                  src="svgs/pin.svg"
                  alt="Pin"
                  className="w-4 h-4 mr-2 mt-2"
                />
              </button>
              <button
                className="text-white text-xl"
                onClick={() => setToggle(!toggle)}
              >
                <img
                  src={"svgs/toggle.svg"}
                  alt="Toggle"
                  className="w-4 h-4 mt-2"
                />
              </button>
            </div>
          </div>
          {/* UI when SideBar is Open */}
          {!toggle ? (
            <div className=" text-sm w-full text-white p-2 dropdown-container max-h-[calc(100vh-14rem)] overflow-y-auto">
              <div className=" text-[0.7rem] text-[#81B3C7] mt-8 mb-2 ml-3">
                GENERAL
              </div>
              <div>
                <span
                  className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2  justify-between items-center cursor-pointer"
                  onClick={() => setShowList(!showList)}
                >
                  <div className="flex justify-center p-1 items-center gap-2 ">
                    <img
                      src="svgs/show.svg"
                      alt="Toggle"
                      className="w-5.5 h-5.5"
                    />
                    <span>Show</span>
                  </div>
                  <span className="ml-2 ">
                    {showList ? (
                      <img
                        src="svgs/arrow_up.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    ) : (
                      <img
                        src="svgs/arrow_down.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    )}
                  </span>
                </span>
                {showList && (
                  <>
                    <div className="flex hover:bg-[#FFFFFF1F] rounded-md cursor-pointer text-[0.9rem] ml-4 gap-2 px-2 py-1 justify-start items-center">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                      <div>Select all</div>
                    </div>
                    <div className=" mt-2 ml-4 mb-2 list-none">
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem] ">
                          <input
                            type="checkbox"
                            checked={showBots}
                            onChange={BotsHandler}
                          />
                          <img
                            src="svgs/bots.svg"
                            alt="Bots"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Bots</span>
                        </div>
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      </button>
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={showpath}
                            onChange={PathsHandler}
                          />
                          <img
                            src="svgs/paths.svg"
                            alt="Paths"
                            className="w-[22px] h-[22px]"
                          />
                          <span>Paths</span>
                        </div>
                        <button
                          onClick={() =>
                            setshowIndividualPath(!showIndividualPath)
                          }
                        >
                          {showIndividualPath ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>
                      {showIndividualPath && paths && paths.length > 0 && (
                        <ul className="path-list  rounded-md  pl-6 mr-2 mb-4">
                          {paths.map((path, index) => (
                            <li
                              key={index}
                              className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                              onClick={() => {
                                setSelectedPath((prevPaths) => {
                                  if (
                                    prevPaths.some(
                                      (p) => p.pathame === path.pathame
                                    )
                                  ) {
                                    return prevPaths.filter(
                                      (p) => p.pathame !== path.pathame
                                    );
                                  } else {
                                    return [...prevPaths, path];
                                  }
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  !!selectedPath.find(
                                    (p) => p.pathame === path.pathame
                                  )
                                }
                                readOnly
                                className="mr-2"
                              />
                              {path.pathame}
                              <button
      onClick={() => handleDeletePath(path.pathame)}
      className="ml-auto text-red-500"
    >
      Delete
    </button>
                            </li>
                          ))}
                        </ul>
                      )}

                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={People}
                            onChange={PeopleHandler}
                          />
                          <img
                            src="svgs/people.svg"
                            alt="People"
                            className="w-[22px] h-[22px] "
                          />
                          <span>People</span>
                        </div>
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      </button>
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={Forklifts}
                            onChange={ForkliftsHandler}
                          />
                          <img
                            src="svgs/forklifts.svg"
                            alt="Forklifts"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Forklifts</span>
                        </div>
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      </button>
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={Materials}
                            onChange={MaterialsHandler}
                          />
                          <img
                            src="svgs/materials.svg"
                            alt="Materials"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Materials</span>
                        </div>
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      </button>
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={showStation}
                            onChange={StationsHandler}
                          />
                          <img
                            src="svgs/stations.svg"
                            alt="Stations"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Stations</span>
                        </div>
                        <button
                          onClick={() =>
                            setshowIndividualStation(!showIndividualStation)
                          }
                        >
                          {showIndividualStation ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                          </button>
                      </button>
                      {showIndividualStation && stationData && stationData.length > 0 && (
                        <ul className="station-list rounded-md pl-6 mr-2 mb-4">
                        {JSON.parse(stationData).map((station, index) => (
                          <li
                            key={index}
                            className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                            onClick={() => {
                              setSelectedStation((prevStations) => {
                                if (prevStations.some((s) => s.stationName === station.stationName)) {
                                  return prevStations.filter((s) => s.stationName !== station.stationName);
                                } else {
                                  return [...prevStations, station];
                                }
                              });
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={!!selectedStation.find((s) => s.stationName === station.stationName)}
                              readOnly
                              className="mr-2"
                            />
                            {station.stationName}
                            <button onClick={() => handleDeleteStation(station.stationName)} className="ml-auto text-red-500">
                              Delete
                            </button>
                          </li>
                        ))}
                      </ul>
                      
                      )}

                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={Zones}
                            onChange={ZonesHandler}
                          />
                          <img
                            src="svgs/zones.svg"
                            alt="Zones"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Zones</span>
                        </div>
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div>
                <span
                  className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer"
                  onClick={() => setRecordList(!recordList)}
                >
                  <div className="flex justify-center items-center gap-2 p-1">
                    <img
                      src="svgs/record.svg"
                      alt="Record"
                      className="w-5.5 h-5.5"
                    />
                    <span>Record</span>
                  </div>
                  <span>
                    {recordList ? (
                      <img
                        src="svgs/arrow_up.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    ) : (
                      <img
                        src="svgs/arrow_down.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    )}
                  </span>
                </span>
                {recordList && (
                  <div className=" ml-7 mb-3 list-none">
                    <button
                      onClick={PathDrawHandler}
                      className={`flex hover:bg-[#FFFFFF1F] ${
                        ToRecord && `bg-[#FFFFFF1F]`
                      } w-full rounded-md p-2 justify-between items-center cursor-pointer`}
                    >
                      <div className="flex gap-[0.5rem] ">
                        <img
                          src="svgs/path_d.svg"
                          alt="Path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Path (draw)</span>
                      </div>
                      {ToRecord?
                      <img
                       src="svgs/arrow_down.svg"
                        alt="Arrow"
                        className="w-5.5 h-5.5"
                      />: <img
                      src="svgs/arrow_right.svg"
                       alt="Arrow"
                       className="w-5.5 h-5.5"
                     />}
                    </button>
                    {ToRecord   && (
                      <div className="pl-4">
                        <button
                        onClick={pathRecordHandler}
                        className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                      >
                        <div className="flex gap-[0.5rem] ">
                          <img
                            src="svgs/path_d.svg"
                            alt="Path"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Save</span>
                        </div>
                        
                      </button>
                      
                      <button
                      onClick={AIpathHandler}
                      className={`flex w-full rounded-md p-2 justify-between items-center cursor-pointer text-sm ${
                        AIpath ? "bg-[#FFFFFF1F]" : "hover:bg-[#FFFFFF1F]"
                      }`}
                    >
                      <div className="flex gap-[0.5rem]">
                       
                        <img
                          src="svgs/path_t.svg"
                          alt="path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>AI Smootheing</span>
                      </div>
                     
                    </button>
                    <button
                        onClick={clearPathHandler}
                        className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                      >
                        <div className="flex gap-[0.5rem] ">
                          <img
                            src="svgs/path_d.svg"
                            alt="Path"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Clear</span>
                        </div>
                        
                      </button>
                    </div>
                      )}
                      <button 
                          onClick={TracePathHandler}
                          className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/path_w.svg"
                          alt="path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Path (Trace)</span>
                      </div>
                      {tracePath?
                      <img
                       src="svgs/arrow_down.svg"
                        alt="Arrow"
                        className="w-5.5 h-5.5"
                      />: <img
                      src="svgs/arrow_right.svg"
                       alt="Arrow"
                       className="w-5.5 h-5.5"
                     />}
                    </button>

                    {tracePath   && (
                      <div className="pl-4">
                        <button
                        onClick={TracePathSaveHandler}
                        className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                      >
                        <div className="flex gap-[0.5rem] ">
                          <img
                            src="svgs/path_d.svg"
                            alt="Path"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Save</span>
                        </div>
                        
                      </button>
                    <button
                        onClick={clearTracedPathHandler}
                        className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                      >
                        <div className="flex gap-[0.5rem] ">
                          <img
                            src="svgs/path_d.svg"
                            alt="Path"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Clear</span>
                        </div>
                        
                      </button>
                    </div>
                      )}


                    <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/path_w.svg"
                          alt="path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Path (waypoint)</span>
                      </div>
                      <img
                        src="svgs/arrow_right.svg"
                        alt="Arrow"
                        className="w-5.5 h-5.5"
                      />
                    </button>

                    <button
                      onClick={recordStationHandler}
                      className={`flex ${
                        showStationRecord && `bg-[#FFFFFF1F]`
                      } hover:bg-[#FFFFFF1F]  w-full rounded-md p-2 justify-between items-center cursor-pointer`}
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/station_d.svg"
                          alt="station"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Station (draw)</span>
                      </div>
                      {showStationRecord ? (
                        <img
                          src="svgs/arrow_up.svg"
                          alt="Toggle"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5 cursor-pointer"
                        />
                      )}
                    </button>
                    {showStationRecord && (
                      <ul className="path-list duration-100 rounded-md pl-6  mb-4">
                        <li
                          onClick={clearStationHandler}
                          className={`text-white py-1 pl-2 hover:bg-[#FFFFFF1F] rounded-md ${
                            stationRecord
                          }`}
                        >
                         <div className="flex gap-[0.5rem] ">
                          <img
                            src="svgs/path_d.svg"
                            alt="Path"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Clear</span>
                        </div>
                        </li>
                        <li
                          onClick={stationSave}
                          className="text-white py-1 pl-2 hover:bg-[#FFFFFF1F] rounded-md"
                        >
                          <div className="flex gap-[0.5rem] ">
                          <img
                            src="svgs/path_d.svg"
                            alt="Path"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Save</span>
                        </div>
                        </li>
                      </ul>
                    )}
                    <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/station_w.svg"
                          alt="station"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Station (way)</span>
                      </div>
                      <img
                        src="svgs/arrow_right.svg"
                        alt="Arrow"
                        className="w-5.5 h-5.5"
                      />
                    </button>

                    <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/station_t.svg"
                          alt="station"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Station (tag)</span>
                      </div>
                      <img
                        src="svgs/arrow_right.svg"
                        alt="Arrow"
                        className="w-5.5 h-5.5"
                      />
                    </button>
                    <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/zone.svg"
                          alt="zone"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Zone</span>
                      </div>
                      <img
                        src="svgs/arrow_right.svg"
                        alt="Arrow"
                        className="w-5.5 h-5.5"
                      />
                    </button>
                  </div>
                )}
              </div>
              <button
                onClick={() => RemoteControlHandler()}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img
                  src="svgs/remote_control.svg"
                  alt="Remote"
                  className="w-5.5 h-5.5"
                />
                <span>Remote control</span>
              </button>
              <button
                onClick={() => UploadTaskHandler()}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img
                  src="svgs/upload_task.svg"
                  alt="Upload"
                  className="w-5.5 h-5.5"
                />
                <span>Upload Task</span>
              </button>
              <button
                onClick={() => AnalyticsHandler()}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img
                  src="svgs/analytics.svg"
                  alt="Analytics"
                  className="w-5.5 h-5.5"
                />
                <span>Analytics</span>
              </button>
              <div className=" text-[0.7rem] text-[#81B3C7] mt-8 mb-2 ml-3">
                ADMIN
              </div>
              <button className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md  gap-2 cursor-pointer p-3">
                <img
                  src="svgs/settings.svg"
                  alt="Settings"
                  className="w-5.5 h-5.5"
                />
                <span>Settings</span>
              </button>
            </div>
          ) : (
            // UI when SideBar is Closed

            <div className=" text-sm flex flex-col text-white p-2 dropdown-container max-h-[calc(100vh-12rem)] justify-center  overflow-y-auto">
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center"
                onClick={() => {
                  setToggle(!toggle);
                  setShowList(true);
                }}
              >
                <img
                  src="svgs/show.svg"
                  alt="Toggle"
                  className="w-5.5 h-[22px] mt-3"
                />
              </button>
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center"
                onClick={() => {
                  setToggle(!toggle);
                  setRecordList(true);
                }}
              >
                <img
                  src="svgs/record.svg"
                  alt="Record"
                  className="w-5.5 h-[22px] mt-3"
                />
              </button>
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/remote_control.svg"
                  alt="Remote"
                  className="w-5.5 h-[22px] mt-3"
                />
              </button>
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/upload_task.svg"
                  alt="Upload"
                  className="w-5.5 h-[22px] mt-3"
                />
              </button>
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/analytics.svg"
                  alt="Analytics"
                  className="w-5.5 h-[22px] mt-3"
                />
              </button>
              <button
                className="flex hover:bg-[#FFFFFF1F] rounded-md h-[100%] p-2 justify-center mt-3"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/settings.svg"
                  alt="Settings"
                  className="w-5.5 h-[22px] mt-7 "
                />
              </button>
            </div>
          )}
          <div className=" justify-center items-center absolute bottom-4 w-full mt-2 p-4 text-white">
            <hr className="border-[#4290AF] pb-6"></hr>
            <div
              className={`profile flex  content-between justify-between ${
                toggle && "flex-col justify-center items-center gap-4"
              } `}
            >
              <img
                src={CompanyLogo}
                alt="Profile"
                className="w-12 h-12 rounded-full bg-white p-0.5"
              />
              {!toggle && (
                <>
                  <div className="profile-info flex flex-col m-1">
                    <div className="font-sans text-l">{companyName}</div>
                    <div className=" font-sans text-xs text-custom-blue">
                   {userEmail}
                    </div>
                  </div>
                </>
              )}
              <button
                className="h-4"
                onClick={handleLogout} >
                <img
                  src="svgs/logout.svg"
                  alt="Logout"
                  className="h-4"
                />
              </button>
            </div>
            <div className="theme-toggle mt-0 flex justify-center">
              {
                <button onClick={handleThemeToggle} className="px-2 rounded-md">
                {toggle === false ? (
                  theme === "dark" ? (
                    <img
                      src="svgs/dark.svg"
                      alt="Dark Mode"
                      className="w-[180%] mr-1 img-full"
                    />
                  ) : (
                    <img
                      src="svgs/light.svg"
                      alt="Light Mode"
                      className="w-[180%] mr-1 img-full"
                    />
                  )
                ) : theme === "dark" ? (
                  <img
                    src="svgs/smallLDModenew.svg"
                    alt="Dark Mode"
                    className="w-[200%] mr-1 img-full"
                  />
                ) : (
                  <img
                    src="svgs/smallLDMode.svg"
                    alt="Light Mode"
                    className=" w-[200%] mr-1 img-full"
                  />
                )}
              </button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
