import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
function Companies() {
  const [companies, setCompanies] = useState([]);
  const navigate=useNavigate();

  useEffect(() => {
  const fetchCompanies = async () => {
    
    try {
      const response = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/companies",{
        userUid:JSON.parse(window.sessionStorage.getItem("data")).uid
      }, {
        withCredentials: true, // if you are dealing with cookies
      });
      
      // No need to call response.json(), use response.data directly
      const data = response.data;
      
      setCompanies(data.companies); // Assuming data.companies is the correct structure
      console.log(companies);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  fetchCompanies();
}, []);

  const setCompanyData=async(companyName)=>{
    const data=await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",{company:companyName});
    console.log(data.data);
    window.sessionStorage.setItem("stationData", JSON.stringify(data.data.stationdata)); 
    window.sessionStorage.setItem("companyData", JSON.stringify(data.data.companydata)); 
    window.sessionStorage.setItem("companyName", companyName);
    setpathData(companyName);
  }
  const setpathData=async(companyName)=>{
    const data=await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getpathdata",{company:companyName});
    window.sessionStorage.setItem("pathData", JSON.stringify(data.data));
    navigate("/home")
  }

  return (
    <div className='w-full h-screen bg-blue-200 flex justify-center items-center'>
      <div className='bg-[#015D81] rounded-md'>
        <img src="svgs/drobot_logo.svg" alt='Drobot'/>
        <div className='bg-white w-[55vw] h-[60vh] overflow-y-auto overflow-hidden shadow-lg justify-center items-center flex flex-wrap gap-4 p-4'>
          {companies.map(company => (
            <div 
              key={company.id} 
              className='h-[5rem] w-[12rem] bg-blue-200 border-black border-[1px] rounded-md flex justify-center items-center text-xl font-semibold flex-wrap shadow-lg cursor-pointer'
              onClick={()=>setCompanyData(company["Company Name"])}
            >
           {  company["Company Name"]}

            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Companies;
