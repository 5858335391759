import Tags from "./Tag.js";
export default class TagsList{
    constructor(TagsList){
        // this.TagsList=TagsList;   can get the initial list
        //or
        this.TagsList={};
    }

    addTag(botId){
        if(!this.TagsList[botId]){
        this.TagsList[botId]=new Tags(botId);}
    }
    removeTag(botId){
        if(this.TagsList[botId]){
            delete this.TagsList[botId];
        }
    }
    getTagInfo(botId){
        return this.TagsList[botId];

    }
    setTagInfo(botId,propertyToChange,propertyValue){
        switch(propertyToChange){
            case "name":
                this.TagsList[botId].setName(propertyValue);
                break;
            case "coordinates":
                if(propertyValue.length==2 && this.TagsList[botId]){
                this.TagsList[botId].setCoordinates(propertyValue[0],propertyValue[1]);}
                break;
            case "battery":
                this.TagsList[botId].setBatteryStatus(propertyValue);
                break;
            case "orientation":
                this.TagsList[botId].setOrientation(propertyValue);
                break;
            case "status":
                this.TagsList[botId].setStatus(propertyValue);

                
                

        }
        
    }
    

}


